import { getAEMCommonHTMLAttributes } from "aem";
import EditableContainer, {
  EditableContainerProps
} from "aem/ui/authoring/EditableContainer";
import classNames from "classnames";
import CSS from "csstype";
import omit from "lodash.omit";
import React from "react";
import { RHReferenceableComponentProps } from "types";
import { RHAnimatableComponentProps } from "utils/animation/types";
import { useAnimation } from "utils/animation/useAnimation";
import { createStyles, makeStyles, Theme } from "utils/material-ui-core";

export type BoxProps = RHAnimatableComponentProps &
  RHReferenceableComponentProps & {
    backgroundColor?: string;
    className?: string;
    marginBottomDesktop?: string;
    marginBottomMobile?: string;
    marginBottomTablet?: string;
    marginLeftDesktop?: string;
    marginLeftMobile?: string;
    marginLeftTablet?: string;
    marginRightDesktop?: string;
    marginRightMobile?: string;
    marginRightTablet?: string;
    marginTopDesktop?: string;
    marginTopMobile?: string;
    marginTopTablet?: string;
    paddingBottomDesktop?: string;
    paddingBottomMobile?: string;
    paddingBottomTablet?: string;
    paddingLeftDesktop?: string;
    paddingLeftMobile?: string;
    paddingLeftTablet?: string;
    paddingRightDesktop?: string;
    paddingRightMobile?: string;
    paddingRightTablet?: string;
    paddingTopDesktop?: string;
    paddingTopMobile?: string;
    paddingTopTablet?: string;
  };

let defaultPadding = "0px";
let defaultMargin = "0px";

const useStyles = (props: BoxProps, restCss: any) =>
  makeStyles((theme: Theme) =>
    createStyles({
      RHBoxClass: {
        paddingLeft: props.paddingLeftMobile
          ? props.paddingLeftMobile
          : defaultPadding,
        paddingRight: props.paddingRightMobile
          ? props.paddingRightMobile
          : defaultPadding,
        paddingTop: props.paddingTopMobile
          ? props.paddingTopMobile
          : defaultPadding,
        paddingBottom: props.paddingBottomMobile
          ? props.paddingBottomMobile
          : defaultPadding,

        marginLeft: props.marginLeftMobile
          ? props.marginLeftMobile
          : defaultMargin,
        marginRight: props.marginRightMobile
          ? props.marginRightMobile
          : defaultMargin,
        marginTop: props.marginTopMobile
          ? props.marginTopMobile
          : defaultMargin,
        marginBottom: props.marginBottomMobile
          ? props.marginBottomMobile
          : defaultMargin,

        [theme.breakpoints.up("sm")]: {
          paddingLeft: props.paddingLeftTablet
            ? props.paddingLeftTablet
            : defaultPadding,
          paddingRight: props.paddingRightTablet
            ? props.paddingRightTablet
            : defaultPadding,
          paddingTop: props.paddingTopTablet
            ? props.paddingTopTablet
            : defaultPadding,
          paddingBottom: props.paddingBottomTablet
            ? props.paddingBottomTablet
            : defaultPadding,

          marginLeft: props.marginLeftTablet
            ? props.marginLeftTablet
            : defaultMargin,
          marginRight: props.marginRightTablet
            ? props.marginRightTablet
            : defaultMargin,
          marginTop: props.marginTopTablet
            ? props.marginTopTablet
            : defaultMargin,
          marginBottom: props.marginBottomTablet
            ? props.marginBottomTablet
            : defaultMargin
        },

        [theme.breakpoints.up("md")]: {
          paddingLeft: props.paddingLeftDesktop
            ? props.paddingLeftDesktop
            : defaultPadding,
          paddingRight: props.paddingRightDesktop
            ? props.paddingRightDesktop
            : defaultPadding,
          paddingTop: props.paddingTopDesktop
            ? props.paddingTopDesktop
            : defaultPadding,
          paddingBottom: props.paddingBottomDesktop
            ? props.paddingBottomDesktop
            : defaultPadding,

          marginLeft: props.marginLeftDesktop
            ? props.marginLeftDesktop
            : defaultMargin,
          marginRight: props.marginRightDesktop
            ? props.marginRightDesktop
            : defaultMargin,
          marginTop: props.marginTopDesktop
            ? props.marginTopDesktop
            : defaultMargin,
          marginBottom: props.marginBottomDesktop
            ? props.marginBottomDesktop
            : defaultMargin
        }
      },
      root: restCss
    })
  );

export const Box: React.FC<BoxProps> = ({
  domRefCallback,
  extraHTMLAttributes = {},
  rhUid,
  rhAnimation,
  backgroundColor,
  children,
  marginBottomDesktop,
  marginBottomMobile,
  marginBottomTablet,
  marginLeftDesktop,
  marginLeftMobile,
  marginLeftTablet,
  marginRightDesktop,
  marginRightMobile,
  marginRightTablet,
  marginTopDesktop,
  marginTopMobile,
  marginTopTablet,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingBottomTablet,
  paddingLeftDesktop,
  paddingLeftMobile,
  paddingLeftTablet,
  paddingRightDesktop,
  paddingRightMobile,
  paddingRightTablet,
  paddingTopDesktop,
  paddingTopMobile,
  paddingTopTablet,
  ...rest
}) => {
  useAnimation({ rhUid, rhAnimation });

  const restCss = omit(rest, [
    "cqPath",
    "cqType",
    "cqItems",
    "className",
    "authoringName",
    "cqItemsOrder",
    "containerProps",
    "childComponents",
    "hideClassNames",
    "modaltrigger_copy",
    "componentProperties",
    "style",
    "box",
    "cq",
    "bannerName",
    "bannerType",
    "divider",
    "dividerCopy",
    "cq:responsive",
    "link_copy_copy",
    "dataAnalyticsId",
    "positionInfo",
    "wistia_copy_copy",
    "link_copy_copy_copy"
  ]);

  const classes = useStyles(
    {
      marginBottomDesktop,
      marginBottomMobile,
      marginBottomTablet,
      marginLeftDesktop,
      marginLeftMobile,
      marginLeftTablet,
      marginRightDesktop,
      marginRightMobile,
      marginRightTablet,
      marginTopDesktop,
      marginTopMobile,
      marginTopTablet,
      paddingBottomDesktop,
      paddingBottomMobile,
      paddingBottomTablet,
      paddingLeftDesktop,
      paddingLeftMobile,
      paddingLeftTablet,
      paddingRightDesktop,
      paddingRightMobile,
      paddingRightTablet,
      paddingTopDesktop,
      paddingTopMobile,
      paddingTopTablet
    },
    restCss
  )();

  const bgStyles: CSS.Properties = {
    backgroundColor: backgroundColor
  };

  return (
    <div
      {...extraHTMLAttributes}
      ref={domRefCallback}
      className={classNames(
        extraHTMLAttributes?.className,
        classes.RHBoxClass,
        classes.root
      )}
      style={{ ...bgStyles }}
    >
      {children}
    </div>
  );
};

Box.defaultProps = {};

export type AEMBoxProps = BoxProps & EditableContainerProps;

export const AEMBox: React.FC<AEMBoxProps> = props => {
  const aemCommonHTMLAttributes = getAEMCommonHTMLAttributes(props);

  const {
    children,
    isInEditor,
    containerProps,
    placeholderComponent,
    showContentInEditMode,
    ...rest
  } = props;

  if (isInEditor) {
    return (
      <EditableContainer
        {...{ containerProps, placeholderComponent, showContentInEditMode }}
      >
        <div {...rest} style={{ display: "block", boxSizing: "inherit" }}>
          {children}
        </div>
      </EditableContainer>
    );
  }

  return (
    <Box
      {...rest}
      className={classNames(rest.className, rest.hideClassNames)}
      extraHTMLAttributes={aemCommonHTMLAttributes}
    >
      {children}
    </Box>
  );
};

AEMBox.defaultProps = {};

export default AEMBox;
